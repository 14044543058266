export const useScrollToTargetOnChange = <T>(page: Ref<T>) => {
  const scrollTarget = ref<HTMLElement | null>(null)
  const header = process.client ? document.querySelector<HTMLElement>('.header')! : null
  const getHeaderHeight = () => header?.offsetHeight || 0
  const getOffset = () => getHeaderHeight() + 40

  const getY = (element: HTMLElement) => {
    const y = (element && element.getBoundingClientRect().top + window.scrollY - getOffset()) || 0
    return y
  }

  watch(page, () => {
    if (scrollTarget.value) {
      window.scrollTo({
        top: getY(scrollTarget.value),
        behavior: 'smooth',
      })
    }
  })
  return {
    scrollTarget,
  }
}
